import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer({locale}) {
	return (
		<footer className="footer">
			<div className="footer__contact">
				<Link to="/home" className="footer__logo">
					<img src="/images/logo_red.svg"/>
					<h4>Innovasoft s.r.o.</h4>
				</Link>
				<a href="tel:+420 604 189 483">+420 604 189 483</a>
				<a href="mailto:info@innovasoft.cz">info@innovasoft.cz</a>
			</div>
			<div className="footer__social">
				<div>
					<div>{locale.footer.followUs}:</div>
					<div className="footer__social-media">
						<img src="/images/linkedin.png" />
						<img src="/images/twitter.png" />
						<img src="/images/facebook.png" />
					</div>
				</div>
				<div>
					<div>© 2024 by</div>
					<div>Innovasoft s.r.o.</div>
				</div>
			</div>
		</footer>
	);
}
