import React from 'react';
import scrollToTop from '../helper/scrollToTop';
import { NavLink } from 'react-router-dom';

export default function HamburgerMenu({
	setOpenMenu,
	lang,
	locale,
	changeLanguage,
}) {
	return (
		<div className="hamburgerMenu">
			<nav>
				<NavLink to="/home" onClick={() => {
					scrollToTop();
					setOpenMenu(false);
				}}>
					{locale.nav.home}
				</NavLink>
				<NavLink to="/services" onClick={() => {
					scrollToTop();
					setOpenMenu(false);
				}}>
					{locale.nav.services}
				</NavLink>
				<NavLink to="/products" onClick={() => {
					scrollToTop();
					setOpenMenu(false);
				}}>
					{locale.nav.products}
				</NavLink>
				<NavLink to="/contact" onClick={() => {
					scrollToTop();
					setOpenMenu(false);
				}}>
					{locale.nav.contact}
				</NavLink>
			</nav>
			<div onClick={() => setOpenMenu(false)} className="hamburgerMenu__close">✕</div>
			<div className="hamburgerMenu__langs">
				<div className={`hamburgerMenu__langs-item ${lang === 'cz' ? 'header__lang-active' : ''}`} onClick={() => changeLanguage('cz')}>CZ</div>
				<div className="hamburgerMenu__divider" />
				<div className={`hamburgerMenu__langs-item ${lang === 'en' ? 'header__lang-active' : ''}`} onClick={() => changeLanguage('en')}>EN</div>
			</div>
		</div>
	);
}