import React from 'react';
import { Link } from 'react-router-dom';
import scrollToTop from '../helper/scrollToTop';

export default function Home({locale}) {
	return (
		<div className="home">
			<div className="home__main">
				<div className="home__main-box">
					<h1>{locale.home.main.header}</h1>
					<h2>{locale.home.main.subHeader}</h2>
					<Link onClick={() => scrollToTop()} to="/services" className="mainButton">
						{locale.home.main.button}
					</Link>
				</div>
			</div>
			<div className="home__ourService">
				<div className="home__ourService-box">
					<div className="home__ourService-header">
						<h3 className="mainHeader">{locale.home.ourService.header}</h3>
						<h4>{locale.home.ourService.subHeader}</h4>
					</div>
					<div className="home__ourService-content">
						<div>
							<div className="homeCardBox">
								<div className="homeCard">
									<img src="/images/img1.svg" />
									<h4>{locale.home.ourService.card1}</h4>
									<div className="homeCard__line"/>
								</div>
								<div className="homeCard">
									<img src="/images/img3.svg" />
									<h4>{locale.home.ourService.card2}</h4>
									<div className="homeCard__line"/>
								</div>
							</div>
							<div className="homeCardBox">
								<div className="homeCard">
									<img src="/images/img2.svg" />
									<h4>{locale.home.ourService.card3}</h4>
									<div className="homeCard__line"/>
								</div>
								<div className="homeCard">
									<img src="/images/img4.svg" />
									<h4>{locale.home.ourService.card4}</h4>
									<div className="homeCard__line"/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="home__about">
				<div className="home__about-box">
					<div className="home__about-section">
						<h3 className="mainHeader">{locale.home.about.header}</h3>
					</div>
					<div className="home__about-section">
						<p className="mainText">{locale.home.about.text}</p>
						<Link to="/vision" className="mainButton" onClick={() => scrollToTop()}>
							{locale.home.about.button}
						</Link>
					</div>
				</div>
			</div>
			<div className="home__aboutNumbers">
				<h3 className="mainHeader">{locale.home.aboutNumbers.header}</h3>
				<div className="home__aboutNumbers-box">
					<div className="numbersItem">
						<h4>{locale.home.aboutNumbers.business.header}</h4>
						<p>{locale.home.aboutNumbers.business.text}</p>
						<div className="numbersItem__line"/>
					</div>
					<div className="numbersItem">
						<h4>{locale.home.aboutNumbers.programming.header}</h4>
						<p>{locale.home.aboutNumbers.programming.text}</p>
						<div className="numbersItem__line"/>
					</div>
					<div className="numbersItem">
						<h4>{locale.home.aboutNumbers.problems.header}</h4>
						<p>{locale.home.aboutNumbers.problems.text}</p>
						<div className="numbersItem__line"/>
					</div>
					<div className="numbersItem">
						<h4>{locale.home.aboutNumbers.travel.header}</h4>
						<p>{locale.home.aboutNumbers.travel.text}</p>
						<div className="numbersItem__line"/>
					</div>
					<div className="numbersItem">
						<h4>{locale.home.aboutNumbers.people.header}</h4>
						<p>{locale.home.aboutNumbers.people.text}</p>
						<div className="numbersItem__line"/>
					</div>
				</div>
			</div>
			<div className="home__emptySection" />
			<div className="home__products">
				<div className="home__products-table">
					<div className="mainHeader">{locale.home.products.header}</div>
					<div>
						<img className="home__products-logo" src="/images/logo_offerto_light.png"/>
					</div>
					<div className="home__products-empty"></div>
					<div className="home__products-empty"></div>
				</div>
				<div className="home__products-main">
					<div className="mainHeader">{locale.home.products.header2}</div>
					<div>
						<p>{locale.home.products.text}</p>
						<Link onClick={() => scrollToTop()} to="/contactForm" className="mainButton">
							{locale.home.products.button}
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}
