import React, {useState, useEffect} from 'react';
import {Routes, Route} from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Products from './pages/Products';
import Services from './pages/Services';
import Vision from './pages/Vision';
import ContactForm from './pages/ContactForm';
import Header from './components/Header';
import Footer from './components/Footer';
import cz from './languages/cz';
import en from './languages/en';

import './styles/index.scss';

export default function App() {
	const [lang, setLang] = useState('cz');

	let locale = cz;

	if (lang === 'en') {
		locale = en;
	}

	useEffect(() => {
		setLang(localStorage.getItem('innovasoftLang') || 'cz');
	}, []);

	return (
		<div className="app">
			<Header locale={locale} lang={lang} setLang={setLang} />
			<main className="content">
				<Routes>
					<Route exact path="/" element={<Home locale={locale} />} />
					<Route exact path="/home" element={<Home locale={locale} />} />
					<Route exact path="/contact" element={<Contact locale={locale} />} />
					<Route exact path="/products" element={<Products locale={locale} />} />
					<Route exact path="/services" element={<Services locale={locale} />} />
					<Route exact path="/vision" element={<Vision locale={locale} />} />
					<Route exact path="/contactForm" element={<ContactForm locale={locale} />} />
				</Routes>
			</main>
			<Footer locale={locale} />
		</div>
	);
}
