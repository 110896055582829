import React from 'react';

export default function Vision({locale}) {
	return (
		<div className="vision">
			<div className="vision__header">
				<h1>{locale.vision.header}</h1>
				<h2>{locale.vision.subHeader}</h2>
			</div>
			<div className="vision__main">
				<div className="vision__main-line">
					<div className="mainHeader">{locale.vision.line1.header}</div>
					<div>
						<p>{locale.vision.line1.text}</p>
					</div>
				</div>
				<div className="vision__main-line">
					<div className="mainHeader">{locale.vision.line2.header}</div>
					<div>
						<p>{locale.vision.line2.text}</p>
					</div>
				</div>
			</div>
		</div>
	);
}