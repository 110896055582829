import React from 'react';

export default function Products({locale}) {
	const redirectToAppPage = (url) => {
		window.open(url, '_blank');
	};

	return (
		<div className="products">
			<div className="products__header">
				<h1>{locale.products.header}</h1>
			</div>
			<main className="products__main">
				<div className="product">
					{/* <h4>{locale.products.offerto.header}</h4> */}
					<img onClick={() => redirectToAppPage('https://offerto.tech/')} src="/images/logo_offerto_light.png"/>
					<p>{locale.products.description}</p>
					<a onClick={() => redirectToAppPage('https://offerto.tech/')} className="mainButton">
						{locale.products.button}
					</a>
				</div>
			</main>
		</div>
	);
}
