export default function scrollToTop() {
	const scrollSettings = {
		top: 0,
	};

	if (!window.location.href.includes('home')) {
		scrollSettings.behavior = 'smooth';
	}

	window.scrollTo(scrollSettings);
}
