import React from 'react';

export default function Input({label, onChange, value, required, className, onBlur}) {
	return(
		<div className="inputWrapper">
			<div className="label">
				{label}
				{required ? <span>*</span> : null}
			</div>
			<input
				onChange={(e) => onChange(e)}
				value={value}
				className={className}
				onBlur={(e) => onBlur(e)}
			/>
		</div>
	);
}