export default {
	nav: {
		home: 'Home',
		services: 'Services',
		products: 'Products',
		contact: 'Contact',
	},
	home: {
		main: {
			header: 'Trading System Builder',
			subHeader: 'Move to better results',
			button: 'Find out more',
		},
		ourService: {
			header: 'What we bring',
			subHeader: 'software just for you with a range of benefits',
			card1: 'Business Process Automation',
			card2: 'Simplifying the customer journey',
			card3: 'Simplifying the administration of business teams',
			card4: 'Increasing sales efficiency',
		},
		about: {
			header: 'A few words about us',
			text: 'We are a dynamic team consisting of two businessmen who bring rich business experience and two programmers who excel in modern technologies. Our passion is to create innovative solutions that simplify and automate business processes. Together, we joined forces to develop a system that makes it easier for companies to work with offers and orders. We believe in the power of collaboration and continuous improvement, thanks to which we offer products that meet the needs of our clients.',
			button: 'Find out more',
		},
		aboutNumbers: {
			header: 'About us in numbers',
			business: {
				header: '20 years',
				text: 'spent by trade',
			},
			programming: {
				header: '15 years',
				text: 'programming experience',
			},
			problems: {
				header: '1000',
				text: 'solved problems',
			},
			travel: {
				header: '1mil',
				text: 'terrain mileage',
			},
			people: {
				header: '4',
				text: 'enthusiasts',
			},
		},
		products: {
			header: 'Our product',
			header2: 'Let yourself be surprised by the lightness of our solution',
			text: 'Leave the administration to us. You take care of your clients!',
			button: 'I want to start',
		},
	},
	services: {
		header: 'Our Unique Services',
		subHeader: 'which will solve you',
		line1: {
			header: 'Business Process Automation',
			text: 'We will review the entire business process and find a solution exactly according to your needs.',
		},
		line2: {
			header: 'Simplifying the customer journey',
			text: 'We will be your customer for a while and thanks to this we will simplify the whole process',
		},
		line3: {
			header: 'Simplifying the administration of business teams',
			text: 'We will also look at your colleagues from the sales department. We will provide them with more time and less stress with deadlines and routine administration.',
		},
		line4: {
			header: 'Increasing sales efficiency',
			text: 'The finale of our journey together will be the interconnection of the individual steps listed above and the creation of an effective way of selling your products and services.',
		},
		result: {
			header: 'We do our best for your better results',
			text: 'Our team of specialists, consisting of two experienced marketers and two talented programmers, focuses on creating a modern system that will make it easier for companies to create offers and orders. We have combined our knowledge of business and technology to develop a solution that streamlines the entire process from the initial offer to the final order. Our goal is not only to save time and reduce the administrative burden, but also to ensure that the entire process is clear and user-friendly. Reliability, innovation and individual approach are the key pillars on which we build our products.',
		},
	},
	products: {
		header: 'Our unique products',
		button: 'I want a product',
		description: 'Offerto will help you and your customers quickly resolve the price of your goods, availability and ordering. Try how easy it is!',
	},
	contact: {
		header: 'Our team',
		text: 'Our team of specialists, consisting of two experienced marketers and two talented programmers, focuses on creating a modern system that will make it easier for companies to create offers and orders. We have combined our knowledge of business and technology to develop a solution that streamlines the entire process. Our goal is not only to save time and reduce the administrative burden, but also to ensure that the entire process is clear and user-friendly. Reliability, innovation and individual approach are the key pillars on which we build our products.',
		participant1: {
			name: 'Pavel Klapka',
			position: 'Business specialist',
			text: 'Pavel has been a businessman for more than 10 years and during that time he managed to close countless deals with maximum customer satisfaction.',
		},
		participant2: {
			name: 'Dušan Duda',
			position: 'Business specialist',
			text: 'Dušan has been involved in business for more than 20 years and you would be hard-pressed to find a better salesman than him.',
		},
		participant3: {
			name: 'Roman Jalowiczor',
			position: 'Programmer',
			text: 'Roman has been a professional backend programmer for over 15 years. He spent most of his career programming one of the best US DME applications',
		},
		participant4: {
			name: 'Programmer',
			position: 'Programmer',
			text: 'Programmer with focus on frontend.',
		},
	},
	vision: {
		header: 'Our vision',
		subHeader: 'Our goal is to become a leader in the automation of business processes through innovative solutions that make work more efficient and save time. We want to offer companies of all sizes a tool that allows them to focus on growth and development, while we take care of simplifying their daily agenda. Our vision is to bring value through technologies that are intuitive, reliable and adaptable to each clients needs. We believe the future belongs to those who can effectively connect business with technology, and we are here to facilitate that transition.',
		line1: {
			header: 'Our story',
			text: 'Our story started with a simple idea: how to make everyday work easier for companies and make their processes more efficient. We joined forces - two marketers with rich business experience and two programmers with a passion for technology - to create a system that helps companies better manage quotes and orders. In the beginning, we were united by the desire for innovation and the desire to create something that really helps. We gradually developed and improved our solutions, listening to the needs of our clients and adapting to changing market requirements. Today, we are proud to be able to offer companies a tool that is not only functional, but also intuitive and reliable. Our story is one of collaboration, continuous improvement and a shared vision – to help businesses grow and succeed in the digital world.',
		},
		line2: {
			header: 'Experienced management',
			text: 'The experienced management of our team combines many years of business experience with deep knowledge of technology. Thanks to this combination, we are able to create solutions that not only meet, but often exceed the expectations of our clients. With an emphasis on innovation and efficiency, we lead our company to constantly grow and bring added value to our customers.',
		},
	},
	contactForm: {
		header: 'Start Now',
		subHeader: 'Only one form separates you from our services',
		text: 'Get started now! Just fill out a simple form and we\'ll get back to you as soon as possible. Let us help you simplify and streamline your business processes.',
		firstName: 'Name',
		lastName: 'Last Name',
		email: 'Email',
		phone: 'Phone',
		company: 'Company',
		position: 'Position',
		button: 'Submit form',
	},
	footer: {
		followUs: 'Follow us',
	},
};
