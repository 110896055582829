export default {
	nav: {
		home: 'Home',
		services: 'Služby',
		products: 'Produkty',
		contact: 'Kontakt',
	},
	home: {
		main: {
			header: 'Tvůrce obchodních systémů',
			subHeader: 'Posun k lepším výsledkům',
			button: 'Zjistit více',
		},
		ourService: {
			header: 'Co přinášíme',
			subHeader: 'software přímo pro vás s řadou výhod',
			card1: 'Automatizaci obchodních procesů',
			card2: 'Zjednodušení cesty zákazníka',
			card3: 'Zjednodušení administrativy obchodních týmů',
			card4: 'Zvýšení efektivity prodeje',
		},
		about: {
			header: 'Pár slov o nás',
			text: 'Jsme dynamický tým složený ze dvou obchodníků, kteří přinášejí bohaté zkušenosti z byznysu, a dvou programátorů, kteří excelují v moderních technologiích. Naší vášní je vytvářet inovativní řešení, která zjednodušují a automatizují firemní procesy. Společně jsme spojili síly, abychom vyvinuli systém, který firmám usnadní práci s nabídkami a objednávkami. Věříme v sílu spolupráce a neustálého zlepšování, díky čemuž nabízíme produkty, které odpovídají potřebám našich klientů.',
			button: 'Zjistit více',
		},
		aboutNumbers: {
			header: 'O nás v číslech',
			business: {
				header: '20let',
				text: 'strávených obchodem',
			},
			programming: {
				header: '15let',
				text: 'zkušeností s programováním',
			},
			problems: {
				header: '1000',
				text: 'vyřešených problémů',
			},
			travel: {
				header: '1mil',
				text: 'najetých kilometrů v terénu',
			},
			people: {
				header: '4',
				text: 'nadšenci',
			},
		},
		products: {
			header: 'Náš produkt',
			header2: 'Nechte se překvapit lehkostí našeho řešení',
			text: 'Administrativu nechte na nás. Vy pečujte o své klienty!',
			button: 'Chci začít',
		},
	},
	services: {
		header: 'Naše jedinečné služby',
		subHeader: 'které Vám vyřeší',
		line1: {
			header: 'Automatizaci obchodních procesů',
			text: 'Celý obchodní proces přezkoumáme a najdeme řešení přesně podle Vašich potřeb.',
		},
		line2: {
			header: 'Zjednodušení cesty zákazníka',
			text: 'Nějaký čas budeme Vašim zákazníkem a díky tomu celý proces zjednodušíme',
		},
		line3: {
			header: 'Zjednodušení administrativy obchodních týmů',
			text: 'Podíváme se také na Vaše kolegy z obchodní oddělení. Zajistíme jim více času a méně stresu s deadliny a rutunní administrativou.',
		},
		line4: {
			header: 'Zvýšení efektivity prodeje',
			text: 'Finále naší společné cesty bude vzájemné propojení jednotlivých výše vypsaných kroků a vytvoření tak efektivního způsobu prodeje Vašich produktů a služeb.',
		},
		result: {
			header: 'Děláme maximum pro Vaše lepší výsledky',
			text: 'Náš tým specialistů, složený ze dvou zkušených obchodníků a dvou talentovaných programátorů, se zaměřuje na vytváření moderního systému, který firmám usnadní vytváření nabídek a objednávek. Spojili jsme naše znalosti z obchodu a technologií, abychom vyvinuli řešení, které zefektivní celý proces od počáteční nabídky až po konečnou objednávku. Naším cílem je nejen ušetřit čas a snížit administrativní zátěž, ale také zajistit, že celý proces bude přehledný a uživatelsky přívětivý. Spolehlivost, inovace a individuální přístup jsou klíčovými pilíři, na kterých stavíme naše produkty.',
		},
	},
	products: {
		header: 'Naše jedinečné produkty',
		button: 'Chci produkt',
		description: 'Offerto Vám i Vašim zákazníkům pomůže rychle vyřešit cenu Vašeho zboží, dostupnost a objednání. Vyzkoušejte, jak jednoduché to je!',
	},
	contact: {
		header: 'Náš tým',
		text: 'Náš tým specialistů, složený ze dvou zkušených obchodníků a dvou talentovaných programátorů, se zaměřuje na vytváření moderního systému, který firmám usnadní vytváření nabídek a objednávek. Spojili jsme naše znalosti z obchodu a technologií, abychom vyvinuli řešení, které zefektivní celý proces. Naším cílem je nejen ušetřit čas a snížit administrativní zátěž, ale také zajistit, že celý proces bude přehledný a uživatelsky přívětivý. Spolehlivost, inovace a individuální přístup jsou klíčovými pilíři, na kterých stavíme naše produkty.',
		participant1: {
			name: 'Pavel Klapka',
			position: 'Obchodní specialista',
			text: 'Pavel je obchodníkem už vice než 10 let a za tu dobu dokazál uzavřít nespočet obchodů s maximální spokojeností zákazníka.',
		},
		participant2: {
			name: 'Dušan Duda',
			position: 'Obchodní specialista',
			text: 'Dušan se věnuje obchodu více než 20 let a tězko by jste hledali lepšího ochodníka než je on.',
		},
		participant3: {
			name: 'Roman Jalowiczor',
			position: 'Programátor',
			text: 'Roman je profesionálním programátorem se zaměřením na backend více než 15 let. Většinu své kariéry programoval jednu z neljepších aplikací v USA v oblasti DME.',
		},
		participant4: {
			name: 'Programátor',
			position: 'Programátor',
			text: 'Programátor se zaměřením na frontend.',
		},
	},
	vision: {
		header: 'Naše vize',
		subHeader: 'Naším cílem je stát se lídrem v oblasti automatizace firemních procesů, a to prostřednictvím inovativních řešení, která zefektivňují práci a šetří čas. Chceme firmám všech velikostí nabídnout nástroj, který jim umožní zaměřit se na růst a rozvoj, zatímco my se postaráme o zjednodušení jejich každodenní agendy. Naše vize je přinášet hodnotu skrze technologie, které jsou intuitivní, spolehlivé a přizpůsobitelné potřebám každého klienta. Věříme, že budoucnost patří těm, kteří dokážou efektivně propojit byznys s technologií, a my jsme zde, abychom tento přechod usnadnili.',
		line1: {
			header: 'Náš příběh',
			text: 'Náš příběh začal u jednoduché myšlenky: jak usnadnit firmám každodenní práci a zefektivnit jejich procesy. Spojili jsme síly – dva obchodníci s bohatými zkušenostmi z byznysu a dva programátoři s vášní pro technologie – abychom vytvořili systém, který firmám pomůže lépe spravovat nabídky a objednávky. Na začátku nás spojovala touha po inovaci a touha vytvořit něco, co skutečně pomáhá. Postupně jsme vyvíjeli a zdokonalovali naše řešení, naslouchali potřebám našich klientů a přizpůsobovali se měnícím se požadavkům trhu. Dnes jsme hrdí na to, že můžeme firmám nabídnout nástroj, který je nejen funkční, ale i intuitivní a spolehlivý. Náš příběh je o spolupráci, neustálém zlepšování a sdílené vizi – pomáhat firmám růst a uspět v digitálním světě.',
		},
		line2: {
			header: 'Zkušené vedení',
			text: 'Zkušené vedení našeho týmu spojuje dlouholeté obchodní zkušenosti s hlubokými znalostmi technologií. Díky této kombinaci jsme schopni vytvářet řešení, která nejen splňují, ale často i překonávají očekávání našich klientů. S důrazem na inovaci a efektivitu vedeme naši firmu k tomu, aby neustále rostla a přinášela přidanou hodnotu našim zákazníkům.',
		},
	},
	contactForm: {
		header: 'Začněte hned',
		subHeader: 'Jen jedne formulář vás dělí od našich služeb',
		text: 'Začněte hned! Stačí vyplnit jednoduchý formulář a my se vám co nejdříve ozveme. Nechte nás pomoci vám zjednodušit a zefektivnit vaše firemní procesy.',
		firstName: 'Jméno',
		lastName: 'Příjmení',
		email: 'Email',
		phone: 'Telefon',
		company: 'Společnost',
		position: 'Pozice',
		button: 'Odeslat formulář',
	},
	footer: {
		followUs: 'Sledujte nás',
	},
};
