import React, {useState} from 'react';
import { NavLink } from 'react-router-dom';
import scrollToTop from '../helper/scrollToTop';
import HamburgerMenu from './HamburgerMenu';

export default function Header({locale, lang, setLang}) {
	const [openMenu, setOpenMenu] = useState(false);
	const changeLanguage = (language) => {
		setLang(language);
		localStorage.setItem('innovasoftLang', language);
	};

	return (
		<nav className="header">
			<NavLink onClick={() => scrollToTop()} to="/home" className="header__logo">
				<img src="/images/logo_white.svg" alt="Innovasoft Logo"/>
				<div>Innovasoft</div>
			</NavLink>
			{openMenu ? <HamburgerMenu locale={locale} setOpenMenu={setOpenMenu} changeLanguage={changeLanguage} lang={lang} /> : null}
			<div className="hamburgerMenu__icon" onClick={() => setOpenMenu(true)}>≡</div>
			<div className="header__box">
				<NavLink
					to="/home"
					onClick={() => scrollToTop()}
					className={({ isActive }) =>
						isActive || window.location.pathname === '/' ? 'header__link header__link-active' : 'header__link'
					}
				>
					{locale.nav.home}
				</NavLink>
				<NavLink
					to="/services"
					onClick={() => scrollToTop()}
					className={({ isActive }) =>
						isActive ? 'header__link header__link-active' : 'header__link'
					}
				>
					{locale.nav.services}
				</NavLink>
				<NavLink
					to="/products"
					onClick={() => scrollToTop()}
					className={({ isActive }) =>
						isActive ? 'header__link header__link-active' : 'header__link'
					}
				>
					{locale.nav.products}
				</NavLink>
				<NavLink
					to="/contact"
					onClick={() => scrollToTop()}
					className={({ isActive }) =>
						isActive ? 'header__link header__link-active' : 'header__link'
					}
				>
					{locale.nav.contact}
				</NavLink>
				<div className="header__lang">
					<div onClick={() => changeLanguage('cz')} className={`header__lang-item ${lang === 'cz' ? 'header__lang-active' : ''}`}>CZ</div>
					<div className="header__lang-divider"></div>
					<div onClick={() => changeLanguage('en')} className={`header__lang-item ${lang === 'en' ? 'header__lang-active' : ''}`}>EN</div>
				</div>
			</div>
		</nav>
	);
}