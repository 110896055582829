import React from 'react';

export default function Services({locale}) {
	return (
		<div className="services">
			<div className="services__header">
				<h1>{locale.services.header}</h1>
				<h2>{locale.services.subHeader}</h2>
			</div>
			<main className="services__main">
				<div className="services__main-line">
					<div>
						<div className="services__main-iconBox boxSecondaryBg">
							<img src="/images/services_icon1.svg"/>
							<div />
						</div>
					</div>
					<div>
						<h4>{locale.services.line1.header}</h4>
						<p>{locale.services.line1.text}</p>
					</div>
				</div>
				<div className="services__main-line">
					<div>
						<div className="services__main-iconBox boxPrimaryBg">
							<img src="/images/services_icon2.svg"/>
							<div />
						</div>
					</div>
					<div>
						<h4>{locale.services.line2.header}</h4>
						<p>{locale.services.line2.text}</p>
					</div>
				</div>
				<div className="services__main-line">
					<div>
						<div className="services__main-iconBox boxSecondaryBg">
							<img src="/images/services_icon3.svg"/>
							<div />
						</div>
					</div>
					<div>
						<h4>{locale.services.line3.header}</h4>
						<p>{locale.services.line3.text}</p>
					</div>
				</div>
				<div className="services__main-line">
					<div>
						<div className="services__main-iconBox boxPrimaryBg">
							<img src="/images/services_icon4.svg"/>
							<div />
						</div>
					</div>
					<div>
						<h4>{locale.services.line4.header}</h4>
						<p>{locale.services.line4.text}</p>
					</div>
				</div>
				<div className="services__main-results">
					<div>
						<h3 className="mainHeader">{locale.services.result.header}</h3>
					</div>
					<div>{locale.services.result.text}</div>
				</div>
			</main>
		</div>
	);
}
