/* eslint-disable no-undef */
import Input from '../components/Input';
import React, {useState, useRef} from 'react';
import validateEmail from '../helper/validateEmail';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';

export default function ContactForm({locale}) {
	const [state, setState] = useState({});
	const [error, setError] = useState({});
	const [formError, setFormError] = useState('');
	const [formMessage, setFormMessage] = useState('');
	const recaptcha = useRef();
	const backendUrl = process.env.REACT_APP_REST_URL;
	const recaptchaKey = process.env.REACT_APP_SITE_KEY;

	const requiredFields = ['firstName', 'lastName', 'email'];

	const setValue = (name, value) => {
		if (requiredFields.includes(name) && !value) {
			setError({...error, [name]: true});
		} else {
			setError({...error, [name]: false});
		}

		setState({...state, [name]: value});
	};

	const checkErrorOnBlur = (name, value) => {
		// Validate email and value in input on blur
		if (((requiredFields.includes(name) && !value) || (name === 'email' && !validateEmail(value))) && !error[name]) {
			setError({...error, [name]: true});
		}
	};

	const sendContactForm = () => {
		const captchaValue = recaptcha.current.getValue();
		if (!captchaValue) {
			return alert('Prosím ověřtě že nejste robot!');
		}

		let allReqFieldsFilled = true;
		const setErrors = {};
		requiredFields.forEach((requiredField) => {
			if (!state[requiredField]) {
				allReqFieldsFilled = false;
				setErrors[requiredField] = true;
			}
		});

		if (!allReqFieldsFilled) {
			setError(setErrors);
			return alert('Prosím vyplňtě všechna povinná pole!');
		}

		if (!validateEmail(state.email)) {
			return alert('Prosím vyplňtě platný email!');
		}

		axios({
			method: 'POST',
			withCredentials: true,
			data: state,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			baseURL: `${backendUrl}/index.php?org_url=&act=sendInnovasoftLead`,
		})
			.then((res) => {
				if (res.data === 0) { // error
					setFormError('Nepodařilo se odeslat email pro kontaktní osobu. Prosím kontaktujte nás na emailu info@rolline.cz děkujeme.');
					setFormMessage('');
				} else { // success
					setState({});
					setFormError('');
					setFormMessage('Odeslání formuláře bylo úspěšné');
				}
			})
			.catch((error) => {
				setFormError('Nepodařilo se odeslat email pro kontaktní osobu. Prosím kontaktujte nás na emailu info@rolline.cz děkujeme.');
				console.error(error);
			});
	};

	return (
		<div className="contactForm">
			<div className="vision__header">
				<h1>{locale.contactForm.header}</h1>
				<h2>{locale.contactForm.subHeader}</h2>
			</div>
			<div className="contactForm__main">
				<div className="contactForm__main-box">
					<div>
						<p>{locale.contactForm.text}</p>
					</div>
					<div className="form">
						<div className="form__line">
							<Input
								onChange={(e) => setValue('firstName', e.target.value)}
								value={state.firstName || ''}
								className={error.firstName ? 'inputError' : ''}
								onBlur={(e) => checkErrorOnBlur('firstName', e.target.value)}
								label={locale.contactForm.firstName}
								required={true}
							/>
							<Input
								onChange={(e) => setValue('lastName', e.target.value)}
								value={state.lastName || ''}
								className={error.lastName ? 'inputError' : ''}
								onBlur={(e) => checkErrorOnBlur('lastName', e.target.value)}
								label={locale.contactForm.lastName}
								required={true}
							/>
						</div>
						<div className="form__line">
							<Input
								onChange={(e) => setValue('email', e.target.value)}
								value={state.email || ''}
								className={error.email ? 'inputError' : ''}
								onBlur={(e) => checkErrorOnBlur('email', e.target.value)}
								label={locale.contactForm.email}
								required={true}
							/>
							<Input
								onChange={(e) => setValue('phone', e.target.value)}
								value={state.phone || ''}
								className={error.phone ? 'inputError' : ''}
								onBlur={(e) => checkErrorOnBlur('phone', e.target.value)}
								label={locale.contactForm.phone}
							/>
						</div>
						<div className="form__line">
							<Input
								onChange={(e) => setValue('company', e.target.value)}
								value={state.company || ''}
								className={error.company ? 'inputError' : ''}
								onBlur={(e) => checkErrorOnBlur('company', e.target.value)}
								label={locale.contactForm.company}
							/>
							<Input
								onChange={(e) => setValue('position', e.target.value)}
								value={state.position || ''}
								className={error.position ? 'inputError' : ''}
								onBlur={(e) => checkErrorOnBlur('position', e.target.value)}
								label={locale.contactForm.position}
							/>
						</div>
						<div className="form__line">
							<button onClick={() => sendContactForm()} className="mainButton">
								{locale.contactForm.button}
							</button>
						</div>
						<ReCAPTCHA ref={recaptcha} sitekey={recaptchaKey} />
						{formError ?
							<div className="form__error">
								{formError}
							</div> : null}
						{formMessage ?
							<div className="form__message">
								{formMessage}
							</div> : null}
					</div>
				</div>
			</div>
		</div>
	);
}